import React from 'react';
import '../styles/components/portrait.css';

const Elise = () => {
    return (
        <div className="imgElise">
            <img className="portrait" src="../asset/img/eliseDebels.png" alt=" portrait Elise Debels"/>
        </div>
    );
};

export default Elise;