import React from 'react';
import '../styles/components/bandeau-msge.css';
const BandeauMassage = () => {
    return (
        <div>
            <img className="bd-massage" src="../asset/img/massages-bandeau.webp" alt="image qui représente les techniques de massages"/>

        </div>
    );
};

export default BandeauMassage;